<template>
  <section class="return-detail">
    <template v-if="Object.keys(info).length">
      <h1 class="return-detail__heading font font_title-l font_bold font_uppercase">
        Возврат №{{ info.number }}
      </h1>
      <time
        class="font font_sm font_grey"
        :datetime="info.return_status_date"
      >
        {{ dateFormatShort(info.return_status_date, true, false, 'от') }}
      </time>

      <Alert
        class="return-detail__alert-status"
        :type="typeAlertStatus"
        :closable="false"
        lg
        @close="closeErrorCancel()"
      >
        <template #title>
          {{ statusName }}
        </template>
        <template v-if="info.return_status.client_description" #content>
          {{ info.return_status.client_description }}
        </template>
      </Alert>

      <section class="return-detail__info return-detail__products">
        <h3 class="return-detail__caption return-detail__caption--mobile">
          Возвращаемые товары
        </h3>
        <CharacteristicsList
          class="return-detail__characteristics"
          :characteristics="productsCharacteristic"
        />

        <table class="return-detail__table">
          <thead>
            <tr>
              <th class="return-detail__table-heading">
                Возвращаемые товары
              </th>
              <th class="return-detail__table-heading">
                Причина возврата
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in info.items"
              :key="item.id"
              class="return-detail__row return-detail__row--product"
            >
              <td class="return-detail__column return-detail__product">
                <ProductIcons
                  :products="[item.product]"
                  type="medium"
                  borderless
                />
                <nuxt-link :to="detailLink(item.product)" class="return-detail__product-detail">
                  <p class="return-detail__product-price">
                    {{ getCurrency(item.price) }}
                  </p>
                  <p class="return-detail__product-info">
                    {{ item.product.name }}
                  </p>
                  <p v-if="productSizes" class="return-detail__product-info">
                    {{ productSizes(item.product) }}
                  </p>
                </nuxt-link>
              </td>
              <td class="return-detail__column return-detail__column--reason">
                {{ item.reason.client_name }}
              </td>
            </tr>
          </tbody>
        </table>

        <client-only>
          <ReturnSum :info="infoSum" class="return-detail__sum" />
        </client-only>

        <div class="return-detail__payment-method">
          <h3 class="return-detail__caption">
            Куда вернутся средства
          </h3>
          <p class="return-detail__text-ico return-detail__text-ico--card">
            {{ info.card_for_return_money }}
          </p>
        </div>
      </section>

      <section v-if="info.sender_name || info.sender_phone" class="return-detail__info">
        <h3 class="return-detail__caption">
          Отправитель
        </h3>
        <p class="return-detail__text-ico return-detail__text-ico--sender">
          {{ info.sender_name }}, {{ phoneFormat(info.sender_phone) }}
        </p>
      </section>

      <section v-if="info.return_method_id" class="return-detail__info">
        <h3 class="return-detail__caption">
          Способ доставки
        </h3>

        <p
          class="return-detail__text-ico"
          :class="{
            'return-detail__text-ico--courier': info.return_method_id === returnMethods.COURIER,
            'return-detail__text-ico--pickup': info.return_method_id === returnMethods.PICKUP,
          }"
        >
          {{ info.return_method_name }}
        </p>

        <p v-if="getAddress" class="return-detail__text">
          <b class="return-detail__text-caption">Адрес:</b>
          {{ getAddress }}
        </p>

        <p
          v-if="info.return_method_id === returnMethods.COURIER && info.return_date"
          class="return-detail__text"
        >
          <b class="return-detail__text-caption">Дата доставки:</b>
          {{ getDate }}
        </p>

        <p
          v-if="info.return_method_id === returnMethods.PICKUP && info.point && info.point.timetable"
          class="return-detail__text"
        >
          <b class="return-detail__text-caption">Часы работы ПВЗ:</b>
          {{ info.point.timetable }}
        </p>

        <p
          v-if="info.return_method_id === returnMethods.PICKUP && info.point && info.point.phone"
          class="return-detail__text"
        >
          <b class="return-detail__text-caption">Телефон для информации:</b>
          <a class="link" :href="`tel:${info.point.phone}`">
            {{ phoneFormat(info.point.phone) }}
          </a>
        </p>

        <p v-if="info.delivery_service_track_number" class="return-detail__text">
          <b class="return-detail__text-caption">Номер накладной:</b>
          {{ info.delivery_service_track_number }}
        </p>

        <p
          v-if="info.delivery_cost"
          class="return-detail__text"
        >
          <b class="return-detail__text-caption">Стоимость доставки:</b>
          {{ getCurrency(info.delivery_cost) }}
        </p>

        <div class="font font_m">
          В случае возврата товара надлежащего качества стоимость почтовых услуг оплачивает Покупатель.
        </div>
      </section>

      <section v-if="info.instruction" class="return-detail__info return-detail__info--borderless">
        <h3 class="return-detail__caption">
          Как оформить товары для возврата
        </h3>
        <Expander
          v-if="isExpander"
          class="return-confirm__expander font font_m"
          :min-height="100"
        >
          <div class="return-detail__text return-detail__text--lg" v-html="info.instruction"></div>
        </Expander>
        <div v-else class="return-detail__text return-detail__text--lg" v-html="info.instruction"></div>
        <CustomLink
          v-if="info.instruction_file_link"
          :to="info.instruction_file_link"
          class="link font_m font_underline"
        >
          Скачать инструкцию
        </CustomLink>
      </section>

      <div class="return-detail__footer">
        <Alert
          v-if="visibleErrorCancel"
          class="return-detail__alert"
          type="error"
          @close="closeErrorCancel()"
        >
          <template #content>
            <p>Произошла ошибка при выполнении операции</p>
          </template>
        </Alert>

        <Button
          v-if="isCanCanceled"
          full
          class="return-detail__action"
          @click="openConfirmCancelModal()"
        >
          Отменить возврат
        </Button>

        <Button
          v-if="isUnfinished"
          :to="{ name: 'profile.return.create-return' }"
          full
          class="return-detail__action"
        >
          Продолжить оформление
        </Button>
      </div>

      <CustomModal
        v-if="visibleConfirmCancelModal"
        small
        empty
        @close="closeConfirmCancelModal()"
        @closeOverlay="closeConfirmCancelModal()"
      >
        <template #content>
          <div class="return-modal">
            <p class="return-modal__title">
              Отменить возврат?
            </p>
            <p class="return-modal__text">
              Вы действительно хотите отменить возврат?
            </p>
            <div v-if="!isPendingCancel" class="return-modal__actions">
              <Button
                theme="secondary"
                class="return-modal__button"
                @click="onCancelReturn()"
              >
                Отменить возврат
              </Button>

              <Button
                theme="tertiary"
                class="return-modal__button"
                @click="closeConfirmCancelModal()"
              >
                Назад
              </Button>
            </div>
            <LoaderSpinner v-else class="return-modal__actions loader-spinner--sm" />
          </div>
        </template>
      </CustomModal>
    </template>

    <p v-else class="return-detail__text">
      Нет данных
    </p>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import { dateFormatShort, phoneFormat, dateFormat } from '@/plugins/formatting';
import { getImageAllFormats } from '@/plugins/helpers/file';
import { getProductDemensions } from '@/plugins/helpers/product';
import { toAddressString } from '@/plugins/helpers/address';
import {
  returnMethods, returnOrderStatus, returnOrderStatusTypes, returnOrderStatusCode,
  returnDecisionStatus, returnDecisionStatusName, returnDecisionStatusTypes,
} from '@/plugins/enums/return';

import Alert from '@/components/elements/Alert';
import CustomModal from '@/components/elements/CustomModal';
import LoaderSpinner from '@/components/elements/LoaderSpinner';
import ProductIcons from '@/components/elements/ProductIcons';
import CharacteristicsList from '@/components/profile/characteristicsList';
import ReturnSum from '@/components/profile/returnSum';
import Button from '@/components/elements/Button';
import './index.css';
import Expander from '~/components/elements/Expander';
import CustomLink from '~/components/elements/CustomLink';

export default {
  name: 'ReturnDetail',

  components: {
    CustomLink,
    Expander,
    Alert,
    CustomModal,
    LoaderSpinner,
    CharacteristicsList,
    ProductIcons,
    ReturnSum,
    Button,
  },

  props: {
    info: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      imageSizes: [80, 80],
      visibleConfirmCancelModal: false,
      visibleErrorCancel: false,
      isPendingCancel: false,
      returnMethods,
      returnOrderStatus,
      returnOrderStatusCode,
      returnOrderStatusTypes,
      returnDecisionStatus,
      returnDecisionStatusName,
      returnDecisionStatusTypes,
      wordVariants: ['товар', 'товаров', 'товара'],
    };
  },

  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    statusId() {
      return this.info.return_status_id;
    },
    statusName() {
      return this.info.return_status?.client_name || this.info.return_status?.name;
    },
    isCanCanceled() {
      const listId = [
        this.returnOrderStatus.NEW,
        this.returnOrderStatus.ACCEPTED,
        this.returnOrderStatus.READY_TO_TRANSFER_TO_LO,
        this.returnOrderStatus.WAITING_TO_TRANSFER_TO_LO,
      ];

      return listId.includes(this.statusId);
    },
    isUnfinished() {
      return this.statusId === this.returnOrderStatus.FORMING;
    },
    typeAlertStatus() {
      let type;

      if (this.returnOrderStatusTypes.acceptedStatuses.includes(this.statusId)) {
        type = 'success';
      } else if (this.returnOrderStatusTypes.declinedStatuses.includes(this.statusId)) {
        type = 'error';
      } else if (this.returnOrderStatusTypes.inProcessStatuses.includes(this.statusId)) {
        type = 'info';
      }

      return type;
    },
    productsCharacteristic() {
      const weight = this.info.items.reduce((acc, item) => acc + Number(item.product.weight), 0);
      const count = this.info.items?.length;
      const sum = this.info.return_items_sum;
      return {
        count: {
          value: count,
          wordVariants: this.wordVariants,
        },
        weight: weight / 1000,
        sum,
      };
    },
    infoSum() {
      return {
        ...this.productsCharacteristic,
        deliveryCost: this.info.delivery_cost,
      }
    },
    getAddress() {
      if (this.info.return_method_id === this.returnMethods.PICKUP) {
        return this.info.point.address.address_string;
      }
      if (this.info.return_from_address) {
        return this.toAddressString(this.info.return_from_address);
      }
      return '';
    },
    getDate() {
      const date = dateFormat(this.info.return_date);

      return `${date}, ${this.info.return_timeslot}`;
    },
    isExpander() {
      return this.info?.instruction?.length > 500
    },
  },

  methods: {
    dateFormatShort,
    getProductDemensions,
    phoneFormat,
    toAddressString,
    getImage(image) {
      return getImageAllFormats(image.id, this.imageSizes[0], this.imageSizes[1]);
    },
    openConfirmCancelModal() {
      this.visibleConfirmCancelModal = true;
    },
    closeConfirmCancelModal() {
      this.visibleConfirmCancelModal = false;
    },
    async onCancelReturn() {
      try {
        this.isPendingCancel = true;
        const result = await this.$api.profile.returnCancel(this.$route.params.id);
        this.$emit('update:info', result);
        this.visibleErrorCancel = false;
      } catch {
        this.visibleErrorCancel = true;
      } finally {
        this.closeConfirmCancelModal();
        this.isPendingCancel = false;
      }
    },
    closeErrorCancel() {
      this.visibleErrorCancel = false;
    },
    productSizes(product) {
      const dimensions = this.getProductDemensions(product);
      return `${dimensions?.name}: ${dimensions?.value}`;
    },
    detailLink(product) {
      return {
        name: 'catalog.category.product',
        params: {
          category: product.category_code || product.categoryCodes[0],
          product: product.code,
        },
      };
    },
  },
}
</script>
