<template>
  <ul
    class="product-icons"
    :class="{
      'product-icons--small': type === 'small',
      'product-icons--medium': type === 'medium',
      'product-icons--large': type === 'large',
      'product-icons--borderless': borderless
    }"
  >
    <li
      v-for="(product, productIdx) in pureProducts"
      :key="`prd-${productIdx}`"
      class="product-icons__item bg-image"
    >
      <nuxt-link
        :to="detailLink(product)"
      >
        <CustomImage
          v-if="getImage(product)"
          class="product-icons__image"
          :image-id="getImage(product)"
          :alt="productName(product)"
          :width="imageSize[0]"
          :height="imageSize[1]"
        />
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'ProductIcons',
  components: {
    CustomImage,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'default',
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageSize () {
      let width;
      let height;

      switch (this.type) {
        case 'small':
          width = 44;
          height = 44;
          break;
        case 'medium':
          width = 80;
          height = 80;
          break;
        case 'large':
          width = 100;
          height = 100;
          break;
        default:
          width = 64;
          height = 64;
      }

      return [width, height];
    },
    pureProducts() {
      return this.products.filter(item => {
        const hasCategory = item.category_code ||
          Array.isArray(item.categoryCodes) && item.categoryCodes[0];
        const hasName = item.name || item.title;

        return hasCategory && hasName;
      });
    },
  },
  methods: {
    getImage(product) {
      if (product.image) {
        return product.image.id
      } else if (Array.isArray(product.media) && product.media.length > 0) {
        return product.media[0].id
      }

      return '';
    },
    productName(product) {
      return product.name || product.title || '';
    },
    detailLink(product) {
      return {
        name: 'catalog.category.product',
        params: {
          category: product.category_code || product.categoryCodes[0],
          product: product.code,
        },
      };
    },
  },
}
</script>
