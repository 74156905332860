<template>
  <ul class="characteristics-list">
    <li
      v-for="(characteristic, characteristicKey) in characteristics"
      :key="`char-${characteristicKey}`"
      class="characteristics-list__item font font_sm font_medium font_grey"
    >
      <template v-if="characteristicKey === 'count'">
        {{ phraseDeclension(characteristic.value, characteristic.wordVariants) }}
      </template>

      <template v-else-if="characteristicKey === 'weight'">
        {{ weightFormat(characteristic) }} кг
      </template>

      <template v-else-if="characteristicKey === 'sum'">
        {{ getCurrency(characteristic) }}
      </template>

      <template v-else>
        {{ characteristic }}
      </template>
    </li>
  </ul>
</template>

<script>
import {mapGetters} from 'vuex';
import './index.css';

import { phraseDeclension, weightFormat } from '@/plugins/formatting';

export default {
  name: 'CharacteristicsList',
  props: {
    characteristics: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
  },
  methods: {
    phraseDeclension,
    weightFormat,
  },
}
</script>
