/* eslint-disable camelcase */
function append(base, value, withDelimiter, delimiter) {
  if (value) base += formatSegment(value, withDelimiter, delimiter);
  return base;
}

function formatSegment(value, withDelimiter = true, delimiter = ', ') {
  return withDelimiter ? `${value}${delimiter}` : value;
}

/**
 *
 * @param {Object} address - Адрес
 * @returns {String} e.g. 'post_index, city, street, house, block, кв. flat, этаж floor'
 */

export function toAddressString(address = {}) {
  const { post_index, region, city, street, house, block, flat, floor } = address;

  let addressString = '';
  addressString = append(addressString, post_index);
  addressString = append(addressString, city || region);
  addressString = append(addressString, street);
  addressString = append(addressString, house, (block || flat || floor) || false);
  addressString = append(addressString, block, (flat || floor) || false);
  if (flat) {
    addressString = append(addressString, `кв. ${flat}`, floor || false);
  }
  if (floor) {
    addressString = append(addressString, `этаж ${floor}`, false);
  }

  return addressString;
}

export function checkUndefined(value) {
  return typeof value !== 'undefined' ? value : '';
}

export function buildAddress(address) {
  const { region, area, city, street, house, flat } = address

  return [ ...new Set([region, area, city, street, house, flat]) ].filter(item => item).join(', ')
}

export default {
  toAddressString,
  checkUndefined,
};
