<template>
  <div class="expander">
    <div ref="content" class="expander__content" :style="{ height: contentHeight }">
      <slot />
    </div>
    <button class="expander__button" @click="isExpanded = !isExpanded">
      <slot name="button" :isExpanded="isExpanded">
        {{ isExpanded ? 'Скрыть' : 'Показать' }}
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Expander',
  props: {
    minHeight: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      isExpanded: false,
      contentHeight: `${this.minHeight}px`,
    };
  },
  watch: {
    isExpanded(value) {
      const { content } = this.$refs;
      this.contentHeight = value ? `${content ? `${content.scrollHeight}px` : 'auto'}` : `${this.minHeight}px`;
    },
  },
};
</script>
