<template>
  <section class="return-sum">
    <p class="return-sum__title font font_title-m font_bold font_uppercase">
      Сумма к возврату
    </p>

    <table class="return-sum__table">
      <tr>
        <td class="return-sum__cell">
          {{ phraseDeclension(info.count.value, info.count.wordVariants) }} &bull; {{ weightFormat(info.weight) }} кг
        </td>
        <td class="return-sum__cell">
          {{ getCurrency(info.sum) }}
        </td>
      </tr>
      <tr>
        <td class="return-sum__cell return-sum__cell--accent">
          Итого к возврату
        </td>
        <td class="return-sum__cell return-sum__cell--accent">
          {{ getCurrency(info.sum) }}
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import { phraseDeclension, weightFormat } from '@/plugins/formatting';
import './index.css';

export default {
  name: 'ReturnSum',

  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
  },
  methods: {
    phraseDeclension,
    weightFormat,
  },
}
</script>
