<template>
  <ReturnDetail :info.sync="info" />
</template>

<script>
import ReturnDetail from '@/components/profile/returnDetail';
import pageMeta from '~/mixins/pageMeta';

export default {
  name: 'ReturnDetailPage',
  components: {
    ReturnDetail,
  },
  mixins: [pageMeta],
  async asyncData({ $api, route, error }) {
    try {
      const response = await $api.profile.getReturn(route.params.id);

      if (!Array.isArray(response)) {
        return {
          pageTitle: `Возврат №${response.number}`,
          info: response,
        }
      }
    } catch (e) {
      if (e.response?.status === 404) {
        error({ statusCode: 404, message: e.message })
      }
      console.error(e);
    }
  },
  data() {
    return {
      info: {},
    }
  },
}
</script>
