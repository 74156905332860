export const returnDecisionStatus = Object.freeze({
  /* Ожидает проверки */
  AWAITING_CHECK: 1,

  /* На проверке */
  CHECKING: 10,

  /* Подтвержден возврат товара */
  CONFIRMED: 20,

  /* Подтвержден возврат денег */
  CONFIRMED_MONEY: 50,

  /* Отклонен */
  DECLINED: 100,
});

export const returnDecisionStatusCode = Object.freeze({
  1: 'AWAITING_CHECK',
  10: 'CHECKING',
  20: 'CONFIRMED',
  50: 'CONFIRMED_MONEY',
  100: 'DECLINED',
});

export const returnDecisionStatusName = Object.freeze({
  AWAITING_CHECK: 'Ожидает проверки',
  CHECKING: 'На проверке',
  CONFIRMED: 'Подтвержден возврат товара',
  CONFIRMED_MONEY: 'Подтвержден возврат денег',
  DECLINED: 'Отклонен',
});

export const returnDecisionStatusTypes = Object.freeze({
  inProcessStatuses: [ 1, 10 ],
  acceptedStatuses: [ 20, 50 ],
  declinedStatuses: [ 100 ],
});

export const returnOrderStatus = Object.freeze({
  /* Формируется */
  FORMING: 1,

  /* Новый */
  NEW: 10,

  /* Подтвержден */
  ACCEPTED: 20,

  /* Готов к передаче в ЛО */
  READY_TO_TRANSFER_TO_LO: 100,

  /* Ожидается передача в ЛО */
  WAITING_TO_TRANSFER_TO_LO: 101,

  /* Передан в ЛО */
  TRANSFERRED_TO_LO: 102,

  /* Принят ЛО */
  RECEIVED_ON_LO: 103,

  /* Доставка до РЦ ЛО */
  DELIVERY_TO_RC_LO: 104,

  /* Возврат принят на РЦ ЛО */
  RECEIVED_ON_RC_LO: 105,

  /* Доставляется */
  TRANSFERRING_TO_CSV: 106,

  /* Ожидается */
  WAITING_ON_CSV: 200,

  /* Принимается */
  RECEIVING_ON_CSV: 300,

  /* Принят */
  RECEIVED_ON_CSV: 310,

  /* Обработан на ЦСВ */
  PROCESSED_ON_CSV: 320,

  /* Отправлен с ЦСВ  CRW */
  TRANSFERRED_FROM_CSV: 330,

  /* Обработан */
  PROCESSED_ON_MERCHANT: 400,

  /* Ожидает завершения */
  WAITING: 450,

  /* Завершен */
  DONE: 500,

  /* Проблемы при транспортировке */
  TROUBLE_IN_TRANSFER: 800,

  /* Отменен */
  CANCELED: 1000,
});

export const returnStatusName = Object.freeze({
  FORMED: 'Формируется',

  NEW: 'Новый',

  CONFIRMED: 'Подтвержден',

  READY_TRANSFER_LO: 'Готов к передаче в ЛО',

  EXPECTED_TRANSFER_LO: 'Ожидается передача в ЛО',

  TRANSFERRED_LO: 'Передан в ЛО',

  DECLINED2: 'Принят ЛО',

  DECLINED3: 'Доставка до РЦ ЛО',

  DECLINED4: 'Передан в ЛО',

  ACCEPTED_RC_LO: 'Возврат принят на РЦ ЛО',

  DELIVERED: 'Доставляется',

  EXPECTED: 'Ожидается',

  ACCEPTANCE: 'Принимается',

  ACCEPTED: 'Принят',

  PROCESSED_CRW: 'Обработан на ЦСВ',

  DECLINED_CRW: 'Отправлен с ЦСВ',

  PROCESSED: 'Обработан',

  AWAITING: 'Ожидает завершения',

  COMPLETED: 'Завершен',

  PROBLEMS: 'Проблемы при транспортировке',

  CANCELED: 'Отменен',
});

export const returnOrderStatusCode = Object.freeze({
  1: 'FORMING',
  10: 'NEW',
  20: 'ACCEPTED',
  100: 'READY_TO_TRANSFER_TO_LO',
  101: 'WAITING_TO_TRANSFER_TO_LO',
  102: 'TRANSFERRED_TO_LO',
  103: 'RECEIVED_ON_LO',
  104: 'DELIVERY_TO_RC_LO',
  105: 'RECEIVED_ON_RC_LO',
  106: 'TRANSFERRING_TO_CSV',
  200: 'WAITING_ON_CSV',
  300: 'RECEIVING_ON_CSV',
  310: 'RECEIVED_ON_CSV',
  320: 'PROCESSED_ON_CSV',
  330: 'TRANSFERRED_FROM_CSV',
  400: 'PROCESSED_ON_MERCHANT',
  450: 'WAITING',
  500: 'DONE',
  800: 'TROUBLE_IN_TRANSFER',
  1000: 'CANCELED',
});

export const returnOrderStatusName = Object.freeze({
  FORMING: 'Формируется возврат',
  NEW: 'Оформлен возврат',
  ACCEPTED: 'Оформлен возврат',
  READY_TO_TRANSFER_TO_LO: 'Оформлен возврат',
  WAITING_TO_TRANSFER_TO_LO: 'Оформлен возврат',
  TRANSFERRED_TO_LO: 'В процессе возврата',
  RECEIVED_ON_LO: 'В процессе возврата',
  DELIVERY_TO_RC_LO: 'В процессе возврата',
  RECEIVED_ON_RC_LO: 'В процессе возврата',
  TRANSFERRING_TO_CSV: 'В процессе возврата',
  WAITING_ON_CSV: 'В процессе возврата',
  RECEIVING_ON_CSV: 'В процессе возврата',
  RECEIVED_ON_CSV: 'В процессе возврата',
  PROCESSED_ON_CSV: 'В процессе возврата',
  TRANSFERRED_FROM_CSV: 'В процессе возврата',
  PROCESSED_ON_MERCHANT: 'В процессе возврата',
  WAITING: 'В процессе возврата',
  DONE: 'Возврат принят',
  TROUBLE_IN_TRANSFER: 'В процессе возврата',
  CANCELED: 'Возврат отклонен',
});

export const returnOrderStatusTypes = Object.freeze({
  acceptedStatuses: [ 500 ],
  declinedStatuses: [ 1000 ],
  inProcessStatuses: [ 1, 10, 20, 100, 101, 102, 103, 104, 105, 106, 200, 300, 310, 320, 330, 400, 450, 800 ],
});

export const returnMethods = Object.freeze({
  COURIER: 1,
  PICKUP: 2,
});
